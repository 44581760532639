<template>
  <v-app>
    <div class="reset-password-container">
      <v-flex xs12 sm6 md4>
        <v-card elevation="2" :loading="loading">
          <div class="pa-5">
            <v-card-text class="pa-2">
              <v-container class="mb-3">
                <v-row align="center" justify="center">
                  <v-img
                    src="@/assets/naja_logo.png"
                    max-height="150"
                    max-width="150"
                  ></v-img>
                </v-row>
              </v-container>
              <div v-show="!success">
                <v-card-title class="justify-center">
                  Recuperar Senha
                </v-card-title>
                <v-card-subtitle align="center">
                  Entre com suas informações pessoais
                </v-card-subtitle>
                <v-form class="mt-3" @submit.prevent="submit">
                  <button type="submit" class="hide"></button>
                  <v-alert
                    v-show="errors.length > 0"
                    outlined
                    dense
                    type="error"
                    class="mb-6"
                  >
                    {{ errors.join(', ') }}
                  </v-alert>
                  <v-radio-group v-model="form.kind" row>
                    <template v-slot:label>
                      <b>Eu sou:</b>
                    </template>
                    <v-radio
                      v-for="option in options"
                      :key="option.value"
                      :label="option.description"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-show="form.kind == 'patient'"
                    label="CPF"
                    autofocus
                    outlined
                    placeholder="000.000.000-00"
                    v-mask="'###.###.###-##'"
                    name="cpf"
                    v-model="form.cpf"
                  ></v-text-field>
                  <v-text-field
                    v-show="form.kind == 'doctor'"
                    label="CRM"
                    autofocus
                    outlined
                    placeholder="UF/0000"
                    name="crm"
                    v-model="form.crm"
                  ></v-text-field>
                  <v-text-field
                    v-show="form.kind == 'partner_place'"
                    label="Login"
                    autofocus
                    outlined
                    name="login"
                    v-model="form.login"
                  ></v-text-field>
                </v-form>
              </div>
              <div class="pt-5 text-center" v-show="success">
                <v-icon color="green" size="55">
                  mdi-checkbox-marked-circle
                </v-icon>
                <div class="text-h4 mb-4">Verifique seu e-mail</div>
                <div class="text-subtitle-1">
                  Nós enviamos um e-mail para <b>{{ email_tip }}</b
                  >. Click no link do e-mail para alterar sua senha.
                </div>
              </div>
            </v-card-text>
            <v-card-actions v-show="!success">
              <a @click="$router.go(-1)" href="#">Voltar</a>
              <v-spacer />
              <v-btn @click="submit" color="primary">Pesquisar</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  data() {
    return {
      loading: false,
      errors: [],
      email_tip: '',
      success: false,
      //eslint-disable-next-line
      crmMask: /[a-zA-Z]{2}[\/]\d*/gm,
      options: [
        { description: 'Paciente', value: 'patient' },
        { description: 'Médico', value: 'doctor' },
        { description: 'Parceiro', value: 'partner_place' },
      ],
      form: {
        kind: 'patient',
        cpf: null,
        crm: null,
        login: null,
      },
    }
  },
  methods: {
    _raw(value) {
      return value == null ? null : value.replace(/[^\w\s]/gi, '')
    },
    submit() {
      this.errors = []
      this.loading = true

      const form = {
        kind: this.form.kind,
        cpf: this._raw(this.form.cpf),
        crm: this._raw(this.form.crm),
        login: this.form.crm,
        naja_login: true,
      }

      Api.resetPasswordInstructions(form)
        .then(response => {
          this.success = true
          this.email_tip = response.data.email_tip
        })
        .catch(error => {
          // debugger
          if (error.response) {
            if (error.response.status == 500) {
              this.errors.push('Erro ao recuperar senha')
            } else if (error.response.status == 404) {
              this.errors.push('Usuário não encontrado')
            } else {
              this.errors.push(error.response.data.error)
            }
          }
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.reset-password-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
